@import './base.scss';
@import './brand.scss';

.osg {
  &-container {
    position: relative;
  }

  &-overlay {
    background: rgba($osg-black, .95);
    display: none;
    position: absolute;
    height: 100vh;
    opacity: 0;
    transition: all .25s ease-out;
    width: 100%;
    z-index: 9999999;

    &[aria-hidden='false'] {
      opacity: 1;
    }

    &--container {
      left: 50%;
      margin: auto;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 80%;
      width: 100%;

      #seogroup .audit_form_a {
        max-width: 80%;

        @media (min-width: 1440px) {
          max-width: 60%;
        }
      }

      .osg-overlay--exit {
        background: $osg-white;
        border-radius: 100%;
        box-shadow: 0 0 10px rgba($osg-black, .25);
        color: $osg-black;
        right: 5%;
        margin: 0;
        padding: 8px 15px;
        position: absolute;
        top: 0;

        @media (min-width: 768px) {
          right: 10%;
        }
        
        @media (min-width: 1024px) {
          right: -2%;
          top: -2%;
        }
      }

      @media (min-width: 1024px) {
        width: 60%;
      }
      
      @media (min-width: 1920px) {
        width: 40%;
      }
    }
  }

  &-banner_lp {
    background: url("../images/osg-lp-banner-xsm.jpg") top center no-repeat;
    background-color: $osg-med-gray;
    background-size: cover;
    min-height: 200px;
    padding: 15px;

    .osg-logo--svg {
      @media (min-width: 768px) {
        min-width: 15%;
        padding: 15px;
      }
      
      @media (min-width: 1024px) {
        min-width: 10%;
        padding: 15px;
      }
      
      @media (min-width: 1440px) {
        min-width: 7%;
        padding: 15px;
      }
      
      @media (min-width: 1920px) {
        min-width: 6%;
        padding: 20px;
      }
    }
    
    @media (min-width: 768px) {
      background: url("../images/osg-lp-banner-sm.jpg") top center no-repeat;
      background-size: cover;
      min-height: 500px;
    }
    
    @media (min-width: 1024px) {
      background: url("../images/osg-lp-banner-md.jpg") top center no-repeat;
      background-size: cover;
      min-height: 600px;
    }
    
    @media (min-width: 1440px) {
      background: url("../images/osg-lp-banner-lg.jpg") top center no-repeat;
      background-size: cover;
      min-height: 700px;
    }
    
    @media (min-width: 1920px) {
      background: url("../images/osg-lp-banner-lg.jpg") top center no-repeat;
      background-size: cover;
      min-height: 1000px;
    }
  }

  &-logo {
    flex-basis: 92%;
    position: relative;

    @media (min-width: 768px) {
      flex-basis: 95%;
    }
    
    @media (min-width: 1024px) {
      flex-basis: 96%;
    }

    &--svg {
      margin: 0 0 10px 0;
      max-width: 50px;
      min-width: 15%;
      width: 15%;

      @media (min-width: 768px) {
        min-width: 8%;
      }
      
      @media (min-width: 1024px) {
        min-width: 10%;
      }
      
      @media (min-width: 1440px) {
        min-width: 50px;
        max-width: 70px;
        margin: 0 0 0 10px;
      }

      @media (min-width: 1920px) {
        min-width: 50px;
        max-width: 90px;
      }
    }

    &--name_lp {
      color: $osg-med-gray;
      display: none;
      font-size: 10px;
      font-weight: 600;
      left: 6px;
      margin: -10px 0 0 0;
      padding: 0;
      position: absolute;
      text-align: center;
      
      @media (min-width: 1280px) {
        display: block;
        left: 12px;
      }

      @media (min-width: 1440px) {
        left: 34px;
      }
      
      @media (min-width: 1920px) {
        left: 15px;
      }
      
      @media (min-width: 2560px) {
        left: 34px;
      }
    }
  }

  &-content {
    padding: 25px;
    text-align: center;

    &-get_started {
      padding: 10px 0;

      @media (min-width: 768px) {
        padding: 20px 0;
      }
    }
  }

  &-heading {
    color: $osg-black;
    font-size: 24px;
    line-height: 1.5;
    margin: 0;

    &.xl {
      font-size: 24px;

      @media (min-width: 375px) {
        padding: 0 10px;
        font-size: 32px;
      }
      
      @media (min-width: 768px) {
        padding: 0 10px;
        font-size: 36px;
      }
      
      @media (min-width: 1024px) {
        padding: 0 10px;
        font-size: 42px;
      }
      
      @media (min-width: 1440px) {
        padding: 0 10px;
        font-size: 60px;
      }
    }

    &.md {
      font-size: 18px;

      &.tel {
        margin: 0;
        padding: 0;
        text-decoration: none;
      }
      
      @media (min-width: 1440px) {
        padding: 0 10px;
        font-size: 34px;
      }
    }

    &.sm {
      font-size: 14px;
      margin: auto;
      padding: 0 25px;
      width: 100%;

      @media (min-width: 375px) {
        font-size: 16px;
        width: 90%;
      }
      
      @media (min-width: 768px) {
        width: 50%;
      }
      
      @media (min-width: 1024px) {
        font-size: 16px;
        width: 70%;
      }
    }
  }

  &-panel--box {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 375px) {
      margin: auto;
      width: 80%;
    }
    
    @media (min-width: 768px) {
      flex-direction: row;
      width: 100%;
    }
    
    @media (min-width: 1024px) {
      flex-direction: row;
      margin: 25px auto;
      width: 90%;
    }
    
    @media (min-width: 1440px) {
      flex-direction: row;
      margin: 25px auto;
      width: 60%;
    }
    
    @media (min-width: 1920px) {
      flex-direction: row;
      margin: 25px auto;
      width: 60%;
    }
    
    @media (min-width: 2560px) {
      flex-direction: row;
      margin: 25px auto;
      width: 50%;
    }
  }

  &-panel {
    border: thin solid $osg-light-gray;
    border-radius: 10px;
    margin: 10px 0 15px 0;
    padding: 10px 10px 25px 10px;
    width: 100%;

    @media (min-width: 768px) {
      margin: 10px 5px 15px 5px;
    }
    
    @media (min-width: 1440px) {
      padding: 10px 10px 35px 10px;
    }

    &--banner {
      background: $osg-lighter-gray;
      height: 125px;

      @media (min-width: 768px) {
        height: 140px;
      }
      
      @media (min-width: 1024px) {
        height: 150px;
      }
      
      @media (min-width: 1920px) {
        height: 200px;
      }

      &.consultation {
        background: url("../images/osg-card-banner-1.jpg") top center no-repeat;
        background-size: cover;
      }
      
      &.audit {
        background: url("../images/osg-card-banner-2.jpg") top center no-repeat;
        background-size: cover;
      }
      
      &.discount {
        background: url("../images/osg-card-banner-3.jpg") top center no-repeat;
        background-size: cover;
      }
    }
    
    &--heading {
      font-size: 16px;
      line-height: 1.5;

      @media (min-width: 1440px) {
        font-size: 18px;
      }

      @media (min-width: 1920px) {
        font-size: 24px;
      }
    }
    
    &--body {
      font-size: 12px;
      line-height: 1.5;
      padding: 0 25px;

      @media (min-width: 1440px) {
        line-height: 1.5;
        font-size: 14px;
      }
      
      @media (min-width: 1920px) {
        line-height: 1.5;
        font-size: 18px;
      }
    }
  }
  
  &-hbar {
    background: $osg-black;
    border: none;
    height: 5px;
    margin: 20px auto;
    width: 40px;

    &.sm {
      width: 25px;
    }
  }

  &--action {
    border-radius: 100px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 25px;
    text-decoration: none;
    transition: all .25s ease-out;

    &.seo {
      background: $osg-blue;
      color: $osg-white;
      transition: all .25s ease-out;

      &:hover {
        background: $osg-dark-blue;
        cursor: pointer;
      }
    }

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  &-contact_lp {
    margin: auto;
    padding: 20px;
    position: relative;
    text-align: center;
    width: 100%;

    &.bg {
      background: $osg-lighter-gray;
    }

    .osg-heading.md {
      display: inline-block;
      padding: 25px 0 0 0;
    }

    @media (min-width: 1920px) {
      padding: 50px;
    }

    &--wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
        margin: 50px auto;
        width: 75%;
      }
      
      @media (min-width: 1440px) {
        flex-direction: row;
        margin: 50px auto;
        width: 50%;
      }
      
      @media (min-width: 1920px) {
        flex-direction: row;
        margin: 50px auto;
        width: 35%;
      }
    }

    &--info {
      flex-basis: 40%;
      padding: 0;
      position: relative;

      @media (min-width: 768px) {
        display: flex;
      }

      @media (min-width: 1024px) {
        border-right: thin solid rgba($osg-light-gray, .5);
        display: block;
        padding: 50px;
      }

      &_details {
        display: flex;
        padding: 20px 0;

        &-icon {
          background: $osg-black;
          height: 40px;
          margin-right: 20px;
          width: 40px;
        }

        &-text {
          border-left: thin solid $osg-light-gray;
          padding: 0 15px;
          position: relative;
          text-align: left;
        }
        
        &-type {
          margin: 0;
          padding: 0;
        }
        
        &-contact {
          margin: 0;
          padding: 0;
        }
      }
    }
    
    &--form {
      flex-basis: 100%;
      position: relative;

      &_thankyou {
        background: rgba($osg-black, .95);
        left: 50%;
        padding: 200px 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        @media (min-width: 1440px) {
          padding: 240px 0;
        }

        &-confirm,
        &-message {
          color: $osg-white;
          margin: 0;
          padding: 0;
        }
      }

      &_group {
        border: none;

        &-label {
          display: none;
        }
        
        &-input {
          border: thin solid $osg-light-gray;
          padding: 20px;
          width: 100%;

          &.msg {
            height: 200px;
          }
        }
      }
    }

    .osg-services--subheading {
      margin-bottom: 25px;
    }

    &--input {
      border: transparent;
      border-bottom: thin solid rgba($osg-black, .25);
      padding: 20px 10px;
      width: 100%;

      @media (min-width: 768px) {
        margin: 10px 0;
        width: 75%;
      }
      
      @media (min-width: 1440px) {
        margin: 20px 0;
        width: 75%;
      }
    }
  }

  &-footer {
    background: $osg-black;
    padding: 15px 0 30px 0;
    text-align: center;

    .osg-logo--svg {
      margin: 0;
      padding: 5px 0;
      width: 15%;

      @media (min-width: 768px) {
        margin: 10px 0;
        min-width: 40px;
      }
    }

    &--privacy {
      padding: 25px 0 0 0;
      position: relative;

      &_link {
        font-size: 8px;
        font-weight: 600;
        color: $osg-med-gray;
        text-decoration: none;
      }
    }
  }
}
