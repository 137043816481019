@import './base.scss';
@import './brand.scss';
@import './lets-create-something-amazing.scss';

.osg {
  &-container {
    position: relative;
  }

  &-navigation {
    background: rgba(#000, .75);
    display: none;
    height: 100vh;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 99;

    &[data-expanded='true'] {
      display: block;
    }

    &--exit {
      color: $osg-light-gray;
      font-size: 3rem;
      font-weight: 100;
      position: absolute;
      text-decoration: none;
      top: 20px;
      right: 20px;
      z-index: 100;

      &_label {
        font-size: 12px;
      }
    }

    &--external {
      background: rgba(rgb(15, 15, 15), 1);
      height: 100vh;
      opacity: 0;
      position: absolute;
      right: -10%;
      top: 0;
      width: 50vw;
    }
    
    &--internal {
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      
      &_link {
        border-bottom: thin solid rgba(#000, 1);
        color: $osg-med-gray;
        display: block;
        font-size: 4rem;
        padding: 25px 200px;
        transition: all .20s ease-out;
        text-decoration: none;

        &:hover {
          color: $osg-white;
        }
      }
    }
  }

  &-banner {
    background: url('../images/osg-banner-bg--desktop-xl.jpg') -90px 0 no-repeat;
    background-size: cover;
    min-height: 290px;
    padding: 25px 20px;
    position: relative;

    @media (min-width: 375px) {
      background: url('../images/osg-banner-bg--desktop-xl.jpg') -60px 0 no-repeat;
      background-size: cover;
    }

    @media (min-width: 400px) {
      background: url('../images/osg-banner-bg--desktop-xl.jpg') -30px 0 no-repeat;
      background-size: cover;
    }
    
    @media (min-width: 768px) {
      background: url('../images/osg-banner-bg--desktop-xl.jpg') 0 0 no-repeat;
      background-size: cover;
      min-height: 460px;
      padding: 25px 25px;
    }
    
    @media (min-width: 1024px) {
      background-size: cover;
      min-height: 75vh;
    }
    
    @media (min-width: 1280px) {
      background: url('../images/osg-banner-bg--desktop-xl.jpg') top center no-repeat;
      background-size: cover;
      min-height: 100vh;
      padding: 25px;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
    }
    
    @media (min-width: 1920px) {
      background: url('../images/osg-banner-bg--desktop-xl.jpg') top center no-repeat;
      background-size: cover;
      min-height: 100vh;
      padding: 25px 45px;
    }
    
    @media (min-width: 2560px) {
      background: url('../images/osg-banner-bg--desktop-xl.jpg') top center no-repeat;
      background-size: cover;
      min-height: 100vh;
      padding: 50px 0 0 60px;
    }

    &-header {
      display: flex;
      flex-direction: row;
    }

    &--heading {
      color: $osg-white;
      font-size: 2.55rem;
      font-weight: 600;
      line-height: 1.10;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 4.5rem;
        line-height: 1;
      }

      @media (min-width: 1024px) {
        font-size: 6.5rem;
        margin: 0;
        width: 50%;
      }
      
      @media (min-width: 1280px) {
        font-size: 4.5rem;
        margin: 25px 0 0 0;
        width: 50%;
      }
      
      @media (min-width: 1440px) {
        font-size: 7rem;
        line-height: 1;
      }

      @media (min-width: 1920px) {
        font-size: 7rem;
        line-height: 1;
        margin: 25px 0 0 0;
        width: 50%;
      }
      
      @media (min-width: 2560px) {
        font-size: 10rem;
        margin: 45px 0 0 0;
        width: 45%;
      }
    }

    &--subheading {
      color: $osg-light-gray;
      font-size: .5rem;
      line-height: 1.25;
      margin: 5px 0 15px 0;

      @media (min-width: 768px) {
        font-size: 1rem;
      }

      @media (min-width: 1024px) {
        font-size: .85rem;
        margin: 10px 0 25px 0;
      }
      
      @media (min-width: 192px) {
        font-size: 1rem;
        margin: 10px 0 25px 0;
      }
    }

    &--action {
      background: $osg-blue;
      border-radius: 100px;
      color: $osg-white;
      display: inline-block;
      font-size: $font-size--1;
      font-weight: 400;
      padding: 10px 15px;
      text-decoration: none;
      transition: all .25s ease-out;

      &.dark {
        background: transparent;
        color: $osg-black;
        border: thin solid $osg-black;
        box-shadow: inset 0 0 0 0 $osg-black;
        padding: 15px 35px;
        transition: all .25s ease-out;

        &:hover {
          background: transparent;
          box-shadow: inset 0 0 0 2px $osg-black;
          cursor: pointer;
        }
      }

      &:hover {
        background: $osg-dark-blue;
      }

      @media (min-width: 768px) {
        font-size: .70rem;
        padding: 15px;
      }

      &[data-dark-theme] {
        background: white;
        color: $osg-black;
        border: 1px solid $osg-black;
        margin: 20px auto;
        text-align: center;
        transition: all .25s ease-out;
        width: 55%;
        
        &:hover {
          background: $osg-black;
          color: $osg-white;
        }

        @media (min-width: 768px) {
          display: block;
          min-width: 170px;
          width: 25%;
        }
        
        @media (min-width: 1440px) {
          max-width: 190px;
        }
      }

      &.pill {
        background: transparent;
        box-shadow: 0 0 0 2px $osg-med-gray;
        display: inline-block;
        flex-grow: 1;
        margin: 30px 5px 20px 5px;
        padding: 10px 10px;
        text-align: center;

        @media (min-width: 1440px) {
          margin: 30px 10px 20px 10px;
        }

        &[data-active] {
          box-shadow: 0 0 0 2px $osg-blue;
        }
      }
    }
  }

  &-banner-logo {
    text-align: center;
    width: 50px;

    @media (min-width: 768px) {
      width: 70px;
    }
    
    @media (min-width: 1024px) {
      width: 60px;
    }
    
    @media (min-width: 1920px) {
      width: 80px;
    }

    &--svg {
      margin: 0 0 5px 0;
    }

    &--name {
      color: $osg-med-gray;
      display: none;
      font-size: $font-size--1;
      font-weight: 600;
      line-height: 1.25;
      margin: 0;
      padding: 0;
      
      @media (min-width: 1280px) {
        display: block;
      }
      
      @media (min-width: 1440px) {
        margin-left: 5px;
      }
      
      @media (min-width: 1920px) {
        font-size: .75rem;
      }
    }
  }

  &-menu {
    flex-basis: 9%;
    margin: 0;
    overflow: hidden;

    &:hover {
      cursor: pointer;

      .osg-menu--bar {
        &:nth-child(3) {
          width: 100%;
        }
        &:nth-child(4) {
          width: 100%;
        }
      }
    }

    @media (min-width: 768px) {
      flex-basis: 5%;
    }

    @media (min-width: 1024px) {
      flex-basis: 4%;
      height: 65px;
      max-width: 45px;
      text-align: center;
      letter-spacing: 2px;
    }

    &--label {
      color: $osg-white;
      display: none;

      @media (min-width: 1440px) {
        display: inline-block;
        font-size: 10px;
      }
    }

    &--bar {
      background: $osg-blue;
      height: 2px;
      margin: 7px 0;
      transition: all .25s ease-out;
      width: 100%;
      
      &:nth-child(3) {
        width: 80%;
      }

      &:nth-child(4) {
        width: 60%;
      }

      @media (min-width: 1920px) {
        height: 3px;
        margin: 10px 0;
      }
    }
  }

  &-main {
    background: $osg-white;
    position: relative;

    @media (min-width: 1280px) {
      position: absolute;
      top: 100vh;
    }
  }

  &-services {
    padding: 20px;

    @media (min-width: 1024px) {
      padding: 50px;
    }
    
    @media (min-width: 1280px) {
      padding: 50px;
    }
    
    @media (min-width: 1920px) {
      padding: 50px;
    }

    &--heading {
      color: $osg-black;
      font-size: $font-size-3;
      margin: 0;
      text-align: center;
      padding: 0;

      @media (min-width: 768px) {
        font-size: 3rem;
      }
      
      @media (min-width: 1024px) {
        font-size: 4rem;
        text-align: left;
      }

      @media (min-width: 1440px) {
        font-size: 5.5rem;
        text-align: left;
      }

      @media (min-width: 1920px) {
        font-size: 7rem;
        text-align: left;
      }

      &.works {
        color: $osg-white;
      }
    }
    
    &--subheading {
      color: $osg-med-gray;
      font-size: .80rem;
      line-height: 1.5;
      margin: 5px 0 0 0;
      text-align: center;
      padding: 0 10px;

      &_social {
        text-decoration: none;
        height: 50px;
        width: 50px;

        &-img {
          height: 35px;
          width: 35px;
        }
      }

      @media (min-width: 768px) {
        font-size: 1rem;
      }

      @media (min-width: 1024px) {
        padding: 0;
        text-align: left;
      }
      
      @media (min-width: 1440px) {
        font-size: 1.25rem;
        margin: 0px 0 0 15px;
        text-align: left;
        width: 35%;
      }
      
      @media (min-width: 2560px) {
        font-size: 1rem;
        margin: 0 0 0 15px;
        text-align: left;
      }
    }

    &--panels {
      display: flex;
      flex-direction: column;
      margin: 10px 0;

      @media (min-width: 768px) {
        flex-flow: row wrap;
        justify-content: space-evenly;
      }
      
      @media (min-width: 1280px) {
        margin: 60px 0 50px 0;
      }
      
      @media (min-width: 2560px) {
        margin: 60px 0 50px 0;
      }
    }
    
    &--panel {
      border: thin solid rgba($osg-light-gray, .25);
      border-radius: 10px;
      padding: 20px;
      margin: 25px auto 0;
      opacity: 0;
      text-align: center;
      transition: all 1s ease-out;
      width: 100%;

      &.fade-in {
        opacity: 1;
      }

      @media (min-width: 768px) {
        width: 48%;
      }
      
      @media (min-width: 1024px) {
        padding: 50px 20px;
        width: 23%;
      }
      
      @media (min-width: 1440px) {
        margin: 0px auto 25px;
        padding: 40px 10px;
      }

      @media (min-width: 1920px) {
        margin: 90px auto;
        padding: 80px 80px;
        width: 24%;
      }

      &_icon {
        background: url('../images/osg-icon-services--web.png') top center no-repeat;
        background-size: contain;
        height: 150px;
        margin: -45px auto 0;
        width: 150px;

        @media (min-width: 1024px) {
          margin: -85px auto 50px;
          width: 250px;
        }
      }
      
      &_heading {
        font-size: $font-size-1;
        font-weight: 400;
        margin: 0 0 10px 0;
        padding: 0;

        @media (min-width: 1024px) {
          font-size: 1.25rem;
        }
       
        @media (min-width: 1440px) {
          font-size: 1.5rem;
        }
        
        @media (min-width: 1920px) {
          font-size: 1.75rem;
        }
      }
      
      &_description {
        color: $osg-med-gray;
        font-size: .80rem;
        line-height: 1.5;
        margin: 0 0 20px 0;
        padding: 0;

        @media (min-width: 1024px) {
          font-size: .75rem;
          line-height: 1.5;
        }
        
        @media (min-width: 1440px) {
          font-size: .85rem;
          line-height: 1.5;
        }
        
        @media (min-width: 1920px) {
          font-size: .95rem;
          line-height: 1.5;
          margin-bottom: 50px;
        }
      }
      
      &_link {
        color: $osg-black;
        font-size: $font-size--1;
        font-weight: 600;
        height: 100px;
        margin: -35px auto 0;
        text-decoration: none;
        width: 100px;

        &:hover {
          color: $osg-black;
        }

        @media (min-width: 1920px) {
          font-size: .75rem;
        }
      }
    }

  }
  
  &-pricing {
    background: $osg-lighter-gray;
    padding: 20px;

    @media (min-width: 1024px) {
      padding: 50px;
    }
    
    @media (min-width: 1280px) {
      padding: 50px;
    }
    
    @media (min-width: 1920px) {
      padding: 50px;
    }

    &--heading {
      color: $osg-black;
      font-size: $font-size-3;
      margin: 10px 0 0 0;
      text-align: center;
      padding: 0;

      @media (min-width: 768px) {
        font-size: 3rem;
      }
      
      @media (min-width: 1024px) {
        font-size: 4rem;
        text-align: left;
      }

      @media (min-width: 1440px) {
        font-size: 5.5rem;
        text-align: left;
      }

      @media (min-width: 1920px) {
        font-size: 7rem;
        text-align: left;
      }

      &.works {
        color: $osg-white;
      }
    }
    
    &--subheading {
      color: $osg-med-gray;
      font-size: .65rem;
      margin: 5px 0 0 0;
      text-align: center;
      padding: 0;

      @media (min-width: 768px) {
        font-size: 1rem;
      }

      @media (min-width: 1024px) {
        text-align: left;
      }
      
      @media (min-width: 1440px) {
        font-size: 1.25rem;
        margin: 0px 0 0 15px;
        text-align: left;
      }
      @media (min-width: 2560px) {
        font-size: 1.25rem;
        margin: 0 0 0 15px;
        text-align: left;
      }
    }

    &--panels {
      display: flex;
      flex-direction: column;
      margin: 25px 0;

      @media (min-width: 768px) {
        flex-flow: row wrap;
        justify-content: space-evenly;
      }
      
      @media (min-width: 1280px) {
        margin: 60px 0 50px 0;
      }
      
      @media (min-width: 2560px) {
        margin: 60px 0 50px 0;
      }
    }
    
    &--panel {
      border: thin solid rgba($osg-light-gray, .25);
      border-radius: 10px;
      padding: 20px;
      margin: 25px auto 0;
      opacity: 0;
      text-align: center;
      transition: all 1s ease-out;
      width: 100%;

      &.fade-in {
        opacity: 1;
      }

      @media (min-width: 768px) {
        width: 48%;
      }
      
      @media (min-width: 1024px) {
        padding: 50px 20px;
        width: 31%;
      }
      
      @media (min-width: 1440px) {
        margin: 0px auto 25px;
        padding: 40px 10px;
      }

      @media (min-width: 1920px) {
        margin: 90px auto;
        padding: 80px 80px;
        width: 24%;
      }

      &_icon {
        background: url('../images/osg-icon-services--web.png') top center no-repeat;
        background-size: contain;
        height: 150px;
        margin: -45px auto 0;
        width: 150px;

        @media (min-width: 1024px) {
          margin: -85px auto 50px;
          width: 250px;
        }
      }
      
      &_heading {
        font-size: $font-size-1;
        font-weight: 400;
        margin: 0;
        padding: 0;

        @media (min-width: 1024px) {
          font-size: 1.25rem;
        }
        
        @media (min-width: 1920px) {
          font-size: 1.55rem;
        }
      }
      
      &_subheading {
        font-size: $font-size-3;
        font-weight: 600;
        margin: 0;
        padding: 15px 0;

        @media (min-width: 1024px) {
          font-size: 3.5rem;
        }
        
        @media (min-width: 1920px) {
          font-size: 3.75rem;
        }
      }
      
      &_description {
        color: $osg-med-gray;
        font-size: $font-size--3;
        line-height: 1.5;
        margin: 0 0 20px 0;
        padding: 0;

        @media (min-width: 1024px) {
          font-size: .75rem;
          line-height: 1.5;
        }
        
        @media (min-width: 1440px) {
          font-size: 1rem;
          line-height: 1.5;
          padding: 0 25px;
        }
        
        @media (min-width: 1920px) {
          font-size: .95rem;
          line-height: 1.5;
          margin-bottom: 50px;
        }
      }
      
      &_link {
        color: $osg-black;
        font-size: $font-size--1;
        font-weight: 600;
        height: 100px;
        margin: -35px auto 0;
        text-decoration: none;
        width: 100px;

        &:hover {
          color: $osg-black;
        }

        @media (min-width: 1920px) {
          font-size: .75rem;
        }
      }
    }

  }

  &-works {
    background: #080a09 url('../images/osg-works-background.jpg') top center no-repeat;
    padding: 25px 15px;
    position: relative;

    @media (min-width: 1440px) {
      padding: 50px;
    }

    &--pills {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      position: relative;

      @media (min-width: 768px) {
        margin: 20px auto 10px;
        width: 50%;
      }
      
      @media (min-width: 1440px) {
        margin: 20px auto 10px;
        width: 30%;
      }
      
      @media (min-width: 1920px) {
        margin: 120px auto 10px;
        width: 25%;
      }
    }

    &--gallery {
      display: flex;
      flex-direction: column;
      position: relative;

      @media (min-width: 768px) {
        flex-direction: row;
        flex-flow: row wrap;
        justify-content: space-evenly;
      }
      
      @media (min-width: 1920px) {
        flex-direction: row;
        flex-flow: row nowrap;
        justify-content: space-evenly;
      }

      &_item {
        height: 250px;
        margin: 10px 0;
        opacity: 0;
        position: relative;
        transition: all 1s ease-out;
        width: 100%;

        &.fade-in {
          opacity: 1;
        }

        &-overlay {
          background: rgba($osg-black, .9);
          color: $osg-white;
          height: 100%;
          opacity: 0;
          position: absolute;
          text-align: center;
          transition: all .25s ease-in-out;
          width: 100%;
          z-index: 10;

          &:hover {
            cursor: pointer;
            opacity: 1; 

            .osg-works--gallery_item-overlay_text {
              top: 50%;
              opacity: 1;
            }
          }

          &_text {
            left: 50%;
            opacity: 0;
            padding: 50px 120px;
            position: absolute;
            top: 60%;
            transform: translate(-50%, -50%);
            transition: all .25s ease-in-out;
            width: 100%;
          }
        }

        &-name {
          color: $osg-blue;
          font-size: 28px;
          font-weight: 600;
          margin: 0;
          padding: 0;
        }
        
        &-desc {
          color: $osg-light-gray;
          font-size: 12px;
          line-height: 1.5;
          margin: 0;
          padding: 10px 0;
        }
        
        &-view {
          color: $osg-white;
          display: block;
          font-size: 9px;
          font-weight: 600;
          margin: 15px 0 0 0;
          padding: 10px 0;
          text-decoration: none;
        }

        @media (min-width: 768px) {
          width: 49%;
        }
        
        @media (min-width: 1280px) {
          height: 450px;
          margin: 5px 0;
        }
        
        @media (min-width: 1920px) {
          height: 300px;
          margin: 9px 0;
          width: 24%;
        }
        
        @media (min-width: 2560px) {
          height: 400px;
          margin: 10px 0;
          width: 24%;
        }

        &.item1 {
          background: url('../images/osg-gallery_item-1.jpg') top center no-repeat;
          background-size: cover;
        }
        &.item2 {
          background: url('../images/osg-gallery_item-1.jpg') top center no-repeat;
          background-size: cover;
        }
        &.item3 {
          background: url('../images/osg-gallery_item-1.jpg') top center no-repeat;
          background-size: cover;
        }
        &.item4 {
          background: url('../images/osg-gallery_item-1.jpg') top center no-repeat;
          background-size: cover;
        }
      }
    }

    &--clients {
      background: #222;
      border: thin solid #222;
      display: flex;
      flex-direction: column;
      margin: -5px 0 0 0;
      padding: 30px 0;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
      }

      &_logo {
        background: rgb(58, 58, 58);
        margin: 0 10px;
        flex-grow: 1;
        margin: 10px auto;
        padding: 50px 50px;
        width: 80%;

        @media (min-width: 1024px) {
          flex-direction: row;
          margin: 10px 5px;
        }
      }
    }
  }

  &-contact {
    margin: auto;
    padding: 20px;
    position: relative;
    text-align: center;
    width: 100%;

    @media (min-width: 1024px) {
      flex-direction: row;
      // margin: 100px auto;
      padding: 50px;
    }
    
    &--wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;

      @media (min-width: 1024px) {
        flex-direction: row;
        margin: 100px auto;
        width: 90%;
      }
      
      @media (min-width: 1440px) {
        flex-direction: row;
        margin: 180px auto;
        width: 50%;
      }
      
      @media (min-width: 1920px) {
        flex-direction: row;
        margin: 180px auto;
        width: 40%;
      }
    }

    &--info {
      flex-basis: 40%;
      padding: 0;
      position: relative;

      @media (min-width: 768px) {
        display: flex;
      }

      @media (min-width: 1024px) {
        border-right: thin solid rgba($osg-light-gray, .5);
        display: block;
        padding: 50px;
      }

      &_details {
        display: flex;
        padding: 20px 0;

        &-icon {
          background: $osg-black;
          height: 40px;
          margin-right: 20px;
          width: 40px;
        }

        &-text {
          border-left: thin solid $osg-light-gray;
          padding: 0 15px;
          position: relative;
          text-align: left;
        }
        
        &-type {
          margin: 0;
          padding: 0;
        }
        
        &-contact {
          margin: 0;
          padding: 0;
        }
      }
    }
    
    &--form {
      flex-basis: 100%;
      position: relative;

      &_thankyou {
        background: rgba($osg-black, .95);
        left: 50%;
        padding: 240px 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        &-confirm,
        &-message {
          color: $osg-white;
          margin: 0;
          padding: 0;
        }
      }

      &_group {
        border: none;

        &-label {
          display: none;
        }
        
        &-input {
          border: thin solid $osg-light-gray;
          padding: 20px;
          width: 100%;

          &.msg {
            height: 200px;
          }
        }
      }
    }

    .osg-services--subheading {
      margin-bottom: 25px;
    }

    &--input {
      border: transparent;
      border-bottom: thin solid rgba($osg-black, .25);
      padding: 20px 10px;
      width: 100%;

      @media (min-width: 768px) {
        margin: 10px 0;
        width: 75%;
      }
      
      @media (min-width: 1440px) {
        margin: 20px 0;
        width: 75%;
      }
    }
  }

  &-footer {
    background: $osg-black;
    padding: 15px 0 30px 0;
    text-align: center;

    .osg-logo--svg {
      margin: 0;
      padding: 5px 0;
      width: 15%;

      @media (min-width: 768px) {
        margin: 10px 0;
        min-width: 40px;
      }
    }

    &--privacy {
      padding: 25px 0 0 0;
      position: relative;

      &_link {
        font-size: 8px;
        font-weight: 600;
        color: $osg-med-gray;
        text-decoration: none;
      }
    }
  }
}
