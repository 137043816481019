html, body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: century-gothic,sans-serif;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
