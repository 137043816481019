html, body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: century-gothic,sans-serif;
  width: 100%; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

html, body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: century-gothic,sans-serif;
  width: 100%; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

.osg-container {
  position: relative; }

.osg-overlay {
  background: rgba(34, 34, 34, 0.95);
  display: none;
  position: absolute;
  height: 100vh;
  opacity: 0;
  -webkit-transition: all .25s ease-out;
  transition: all .25s ease-out;
  width: 100%;
  z-index: 9999999; }
  .osg-overlay[aria-hidden='false'] {
    opacity: 1; }
  .osg-overlay--container {
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 80%;
    width: 100%; }
    .osg-overlay--container #seogroup .audit_form_a {
      max-width: 80%; }
      @media (min-width: 1440px) {
        .osg-overlay--container #seogroup .audit_form_a {
          max-width: 60%; } }
    .osg-overlay--container .osg-overlay--exit {
      background: #fff;
      border-radius: 100%;
      -webkit-box-shadow: 0 0 10px rgba(34, 34, 34, 0.25);
              box-shadow: 0 0 10px rgba(34, 34, 34, 0.25);
      color: #222;
      right: 5%;
      margin: 0;
      padding: 8px 15px;
      position: absolute;
      top: 0; }
      @media (min-width: 768px) {
        .osg-overlay--container .osg-overlay--exit {
          right: 10%; } }
      @media (min-width: 1024px) {
        .osg-overlay--container .osg-overlay--exit {
          right: -2%;
          top: -2%; } }
    @media (min-width: 1024px) {
      .osg-overlay--container {
        width: 60%; } }
    @media (min-width: 1920px) {
      .osg-overlay--container {
        width: 40%; } }

.osg-banner_lp {
  background: url(../images/osg-lp-banner-xsm.jpg) top center no-repeat;
  background-color: #6D6E71;
  background-size: cover;
  min-height: 200px;
  padding: 15px; }
  @media (min-width: 768px) {
    .osg-banner_lp .osg-logo--svg {
      min-width: 15%;
      padding: 15px; } }
  @media (min-width: 1024px) {
    .osg-banner_lp .osg-logo--svg {
      min-width: 10%;
      padding: 15px; } }
  @media (min-width: 1440px) {
    .osg-banner_lp .osg-logo--svg {
      min-width: 7%;
      padding: 15px; } }
  @media (min-width: 1920px) {
    .osg-banner_lp .osg-logo--svg {
      min-width: 6%;
      padding: 20px; } }
  @media (min-width: 768px) {
    .osg-banner_lp {
      background: url(../images/osg-lp-banner-sm.jpg) top center no-repeat;
      background-size: cover;
      min-height: 500px; } }
  @media (min-width: 1024px) {
    .osg-banner_lp {
      background: url(../images/osg-lp-banner-md.jpg) top center no-repeat;
      background-size: cover;
      min-height: 600px; } }
  @media (min-width: 1440px) {
    .osg-banner_lp {
      background: url(../images/osg-lp-banner-lg.jpg) top center no-repeat;
      background-size: cover;
      min-height: 700px; } }
  @media (min-width: 1920px) {
    .osg-banner_lp {
      background: url(../images/osg-lp-banner-lg.jpg) top center no-repeat;
      background-size: cover;
      min-height: 1000px; } }

.osg-logo {
  -ms-flex-preferred-size: 92%;
      flex-basis: 92%;
  position: relative; }
  @media (min-width: 768px) {
    .osg-logo {
      -ms-flex-preferred-size: 95%;
          flex-basis: 95%; } }
  @media (min-width: 1024px) {
    .osg-logo {
      -ms-flex-preferred-size: 96%;
          flex-basis: 96%; } }
  .osg-logo--svg {
    margin: 0 0 10px 0;
    max-width: 50px;
    min-width: 15%;
    width: 15%; }
    @media (min-width: 768px) {
      .osg-logo--svg {
        min-width: 8%; } }
    @media (min-width: 1024px) {
      .osg-logo--svg {
        min-width: 10%; } }
    @media (min-width: 1440px) {
      .osg-logo--svg {
        min-width: 50px;
        max-width: 70px;
        margin: 0 0 0 10px; } }
    @media (min-width: 1920px) {
      .osg-logo--svg {
        min-width: 50px;
        max-width: 90px; } }
  .osg-logo--name_lp {
    color: #6D6E71;
    display: none;
    font-size: 10px;
    font-weight: 600;
    left: 6px;
    margin: -10px 0 0 0;
    padding: 0;
    position: absolute;
    text-align: center; }
    @media (min-width: 1280px) {
      .osg-logo--name_lp {
        display: block;
        left: 12px; } }
    @media (min-width: 1440px) {
      .osg-logo--name_lp {
        left: 34px; } }
    @media (min-width: 1920px) {
      .osg-logo--name_lp {
        left: 15px; } }
    @media (min-width: 2560px) {
      .osg-logo--name_lp {
        left: 34px; } }

.osg-content {
  padding: 25px;
  text-align: center; }
  .osg-content-get_started {
    padding: 10px 0; }
    @media (min-width: 768px) {
      .osg-content-get_started {
        padding: 20px 0; } }

.osg-heading {
  color: #222;
  font-size: 24px;
  line-height: 1.5;
  margin: 0; }
  .osg-heading.xl {
    font-size: 24px; }
    @media (min-width: 375px) {
      .osg-heading.xl {
        padding: 0 10px;
        font-size: 32px; } }
    @media (min-width: 768px) {
      .osg-heading.xl {
        padding: 0 10px;
        font-size: 36px; } }
    @media (min-width: 1024px) {
      .osg-heading.xl {
        padding: 0 10px;
        font-size: 42px; } }
    @media (min-width: 1440px) {
      .osg-heading.xl {
        padding: 0 10px;
        font-size: 60px; } }
  .osg-heading.md {
    font-size: 18px; }
    .osg-heading.md.tel {
      margin: 0;
      padding: 0;
      text-decoration: none; }
    @media (min-width: 1440px) {
      .osg-heading.md {
        padding: 0 10px;
        font-size: 34px; } }
  .osg-heading.sm {
    font-size: 14px;
    margin: auto;
    padding: 0 25px;
    width: 100%; }
    @media (min-width: 375px) {
      .osg-heading.sm {
        font-size: 16px;
        width: 90%; } }
    @media (min-width: 768px) {
      .osg-heading.sm {
        width: 50%; } }
    @media (min-width: 1024px) {
      .osg-heading.sm {
        font-size: 16px;
        width: 70%; } }

.osg-panel--box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%; }
  @media (min-width: 375px) {
    .osg-panel--box {
      margin: auto;
      width: 80%; } }
  @media (min-width: 768px) {
    .osg-panel--box {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      width: 100%; } }
  @media (min-width: 1024px) {
    .osg-panel--box {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      margin: 25px auto;
      width: 90%; } }
  @media (min-width: 1440px) {
    .osg-panel--box {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      margin: 25px auto;
      width: 60%; } }
  @media (min-width: 1920px) {
    .osg-panel--box {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      margin: 25px auto;
      width: 60%; } }
  @media (min-width: 2560px) {
    .osg-panel--box {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      margin: 25px auto;
      width: 50%; } }

.osg-panel {
  border: thin solid #aeaeae;
  border-radius: 10px;
  margin: 10px 0 15px 0;
  padding: 10px 10px 25px 10px;
  width: 100%; }
  @media (min-width: 768px) {
    .osg-panel {
      margin: 10px 5px 15px 5px; } }
  @media (min-width: 1440px) {
    .osg-panel {
      padding: 10px 10px 35px 10px; } }
  .osg-panel--banner {
    background: #f7f7f7;
    height: 125px; }
    @media (min-width: 768px) {
      .osg-panel--banner {
        height: 140px; } }
    @media (min-width: 1024px) {
      .osg-panel--banner {
        height: 150px; } }
    @media (min-width: 1920px) {
      .osg-panel--banner {
        height: 200px; } }
    .osg-panel--banner.consultation {
      background: url(../images/osg-card-banner-1.jpg) top center no-repeat;
      background-size: cover; }
    .osg-panel--banner.audit {
      background: url(../images/osg-card-banner-2.jpg) top center no-repeat;
      background-size: cover; }
    .osg-panel--banner.discount {
      background: url(../images/osg-card-banner-3.jpg) top center no-repeat;
      background-size: cover; }
  .osg-panel--heading {
    font-size: 16px;
    line-height: 1.5; }
    @media (min-width: 1440px) {
      .osg-panel--heading {
        font-size: 18px; } }
    @media (min-width: 1920px) {
      .osg-panel--heading {
        font-size: 24px; } }
  .osg-panel--body {
    font-size: 12px;
    line-height: 1.5;
    padding: 0 25px; }
    @media (min-width: 1440px) {
      .osg-panel--body {
        line-height: 1.5;
        font-size: 14px; } }
    @media (min-width: 1920px) {
      .osg-panel--body {
        line-height: 1.5;
        font-size: 18px; } }

.osg-hbar {
  background: #222;
  border: none;
  height: 5px;
  margin: 20px auto;
  width: 40px; }
  .osg-hbar.sm {
    width: 25px; }

.osg--action {
  border-radius: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 25px;
  text-decoration: none;
  -webkit-transition: all .25s ease-out;
  transition: all .25s ease-out; }
  .osg--action.seo {
    background: #00aeef;
    color: #fff;
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out; }
    .osg--action.seo:hover {
      background: #049ed6;
      cursor: pointer; }
  @media (min-width: 768px) {
    .osg--action {
      font-size: 14px; } }

.osg-contact_lp {
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%; }
  .osg-contact_lp.bg {
    background: #f7f7f7; }
  .osg-contact_lp .osg-heading.md {
    display: inline-block;
    padding: 25px 0 0 0; }
  @media (min-width: 1920px) {
    .osg-contact_lp {
      padding: 50px; } }
  .osg-contact_lp--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
    width: 100%; }
    @media (min-width: 1024px) {
      .osg-contact_lp--wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin: 50px auto;
        width: 75%; } }
    @media (min-width: 1440px) {
      .osg-contact_lp--wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin: 50px auto;
        width: 50%; } }
    @media (min-width: 1920px) {
      .osg-contact_lp--wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin: 50px auto;
        width: 35%; } }
  .osg-contact_lp--info {
    -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    padding: 0;
    position: relative; }
    @media (min-width: 768px) {
      .osg-contact_lp--info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    @media (min-width: 1024px) {
      .osg-contact_lp--info {
        border-right: thin solid rgba(174, 174, 174, 0.5);
        display: block;
        padding: 50px; } }
    .osg-contact_lp--info_details {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 20px 0; }
      .osg-contact_lp--info_details-icon {
        background: #222;
        height: 40px;
        margin-right: 20px;
        width: 40px; }
      .osg-contact_lp--info_details-text {
        border-left: thin solid #aeaeae;
        padding: 0 15px;
        position: relative;
        text-align: left; }
      .osg-contact_lp--info_details-type {
        margin: 0;
        padding: 0; }
      .osg-contact_lp--info_details-contact {
        margin: 0;
        padding: 0; }
  .osg-contact_lp--form {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    position: relative; }
    .osg-contact_lp--form_thankyou {
      background: rgba(34, 34, 34, 0.95);
      left: 50%;
      padding: 200px 0;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 100%; }
      @media (min-width: 1440px) {
        .osg-contact_lp--form_thankyou {
          padding: 240px 0; } }
      .osg-contact_lp--form_thankyou-confirm, .osg-contact_lp--form_thankyou-message {
        color: #fff;
        margin: 0;
        padding: 0; }
    .osg-contact_lp--form_group {
      border: none; }
      .osg-contact_lp--form_group-label {
        display: none; }
      .osg-contact_lp--form_group-input {
        border: thin solid #aeaeae;
        padding: 20px;
        width: 100%; }
        .osg-contact_lp--form_group-input.msg {
          height: 200px; }
  .osg-contact_lp .osg-services--subheading {
    margin-bottom: 25px; }
  .osg-contact_lp--input {
    border: transparent;
    border-bottom: thin solid rgba(34, 34, 34, 0.25);
    padding: 20px 10px;
    width: 100%; }
    @media (min-width: 768px) {
      .osg-contact_lp--input {
        margin: 10px 0;
        width: 75%; } }
    @media (min-width: 1440px) {
      .osg-contact_lp--input {
        margin: 20px 0;
        width: 75%; } }

.osg-footer {
  background: #222;
  padding: 15px 0 30px 0;
  text-align: center; }
  .osg-footer .osg-logo--svg {
    margin: 0;
    padding: 5px 0;
    width: 15%; }
    @media (min-width: 768px) {
      .osg-footer .osg-logo--svg {
        margin: 10px 0;
        min-width: 40px; } }
  .osg-footer--privacy {
    padding: 25px 0 0 0;
    position: relative; }
    .osg-footer--privacy_link {
      font-size: 8px;
      font-weight: 600;
      color: #6D6E71;
      text-decoration: none; }

.osg-container {
  position: relative; }

.osg-navigation {
  background: rgba(0, 0, 0, 0.75);
  display: none;
  height: 100vh;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 99; }
  .osg-navigation[data-expanded='true'] {
    display: block; }
  .osg-navigation--exit {
    color: #aeaeae;
    font-size: 3rem;
    font-weight: 100;
    position: absolute;
    text-decoration: none;
    top: 20px;
    right: 20px;
    z-index: 100; }
    .osg-navigation--exit_label {
      font-size: 12px; }
  .osg-navigation--external {
    background: #0f0f0f;
    height: 100vh;
    opacity: 0;
    position: absolute;
    right: -10%;
    top: 0;
    width: 50vw; }
  .osg-navigation--internal {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%; }
    .osg-navigation--internal_link {
      border-bottom: thin solid black;
      color: #6D6E71;
      display: block;
      font-size: 4rem;
      padding: 25px 200px;
      -webkit-transition: all .20s ease-out;
      transition: all .20s ease-out;
      text-decoration: none; }
      .osg-navigation--internal_link:hover {
        color: #fff; }

.osg-banner {
  background: url(../images/osg-banner-bg--desktop-xl.jpg) -90px 0 no-repeat;
  background-size: cover;
  min-height: 290px;
  padding: 25px 20px;
  position: relative; }
  @media (min-width: 375px) {
    .osg-banner {
      background: url(../images/osg-banner-bg--desktop-xl.jpg) -60px 0 no-repeat;
      background-size: cover; } }
  @media (min-width: 400px) {
    .osg-banner {
      background: url(../images/osg-banner-bg--desktop-xl.jpg) -30px 0 no-repeat;
      background-size: cover; } }
  @media (min-width: 768px) {
    .osg-banner {
      background: url(../images/osg-banner-bg--desktop-xl.jpg) 0 0 no-repeat;
      background-size: cover;
      min-height: 460px;
      padding: 25px 25px; } }
  @media (min-width: 1024px) {
    .osg-banner {
      background-size: cover;
      min-height: 75vh; } }
  @media (min-width: 1280px) {
    .osg-banner {
      background: url(../images/osg-banner-bg--desktop-xl.jpg) top center no-repeat;
      background-size: cover;
      min-height: 100vh;
      padding: 25px;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw; } }
  @media (min-width: 1920px) {
    .osg-banner {
      background: url(../images/osg-banner-bg--desktop-xl.jpg) top center no-repeat;
      background-size: cover;
      min-height: 100vh;
      padding: 25px 45px; } }
  @media (min-width: 2560px) {
    .osg-banner {
      background: url(../images/osg-banner-bg--desktop-xl.jpg) top center no-repeat;
      background-size: cover;
      min-height: 100vh;
      padding: 50px 0 0 60px; } }
  .osg-banner-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
  .osg-banner--heading {
    color: #fff;
    font-size: 2.55rem;
    font-weight: 600;
    line-height: 1.10;
    margin: 0; }
    @media (min-width: 768px) {
      .osg-banner--heading {
        font-size: 4.5rem;
        line-height: 1; } }
    @media (min-width: 1024px) {
      .osg-banner--heading {
        font-size: 6.5rem;
        margin: 0;
        width: 50%; } }
    @media (min-width: 1280px) {
      .osg-banner--heading {
        font-size: 4.5rem;
        margin: 25px 0 0 0;
        width: 50%; } }
    @media (min-width: 1440px) {
      .osg-banner--heading {
        font-size: 7rem;
        line-height: 1; } }
    @media (min-width: 1920px) {
      .osg-banner--heading {
        font-size: 7rem;
        line-height: 1;
        margin: 25px 0 0 0;
        width: 50%; } }
    @media (min-width: 2560px) {
      .osg-banner--heading {
        font-size: 10rem;
        margin: 45px 0 0 0;
        width: 45%; } }
  .osg-banner--subheading {
    color: #aeaeae;
    font-size: .5rem;
    line-height: 1.25;
    margin: 5px 0 15px 0; }
    @media (min-width: 768px) {
      .osg-banner--subheading {
        font-size: 1rem; } }
    @media (min-width: 1024px) {
      .osg-banner--subheading {
        font-size: .85rem;
        margin: 10px 0 25px 0; } }
    @media (min-width: 192px) {
      .osg-banner--subheading {
        font-size: 1rem;
        margin: 10px 0 25px 0; } }
  .osg-banner--action {
    background: #00aeef;
    border-radius: 100px;
    color: #fff;
    display: inline-block;
    font-size: 0.5rem;
    font-weight: 400;
    padding: 10px 15px;
    text-decoration: none;
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out; }
    .osg-banner--action.dark {
      background: transparent;
      color: #222;
      border: thin solid #222;
      -webkit-box-shadow: inset 0 0 0 0 #222;
              box-shadow: inset 0 0 0 0 #222;
      padding: 15px 35px;
      -webkit-transition: all .25s ease-out;
      transition: all .25s ease-out; }
      .osg-banner--action.dark:hover {
        background: transparent;
        -webkit-box-shadow: inset 0 0 0 2px #222;
                box-shadow: inset 0 0 0 2px #222;
        cursor: pointer; }
    .osg-banner--action:hover {
      background: #049ed6; }
    @media (min-width: 768px) {
      .osg-banner--action {
        font-size: .70rem;
        padding: 15px; } }
    .osg-banner--action[data-dark-theme] {
      background: white;
      color: #222;
      border: 1px solid #222;
      margin: 20px auto;
      text-align: center;
      -webkit-transition: all .25s ease-out;
      transition: all .25s ease-out;
      width: 55%; }
      .osg-banner--action[data-dark-theme]:hover {
        background: #222;
        color: #fff; }
      @media (min-width: 768px) {
        .osg-banner--action[data-dark-theme] {
          display: block;
          min-width: 170px;
          width: 25%; } }
      @media (min-width: 1440px) {
        .osg-banner--action[data-dark-theme] {
          max-width: 190px; } }
    .osg-banner--action.pill {
      background: transparent;
      -webkit-box-shadow: 0 0 0 2px #6D6E71;
              box-shadow: 0 0 0 2px #6D6E71;
      display: inline-block;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      margin: 30px 5px 20px 5px;
      padding: 10px 10px;
      text-align: center; }
      @media (min-width: 1440px) {
        .osg-banner--action.pill {
          margin: 30px 10px 20px 10px; } }
      .osg-banner--action.pill[data-active] {
        -webkit-box-shadow: 0 0 0 2px #00aeef;
                box-shadow: 0 0 0 2px #00aeef; }

.osg-banner-logo {
  text-align: center;
  width: 50px; }
  @media (min-width: 768px) {
    .osg-banner-logo {
      width: 70px; } }
  @media (min-width: 1024px) {
    .osg-banner-logo {
      width: 60px; } }
  @media (min-width: 1920px) {
    .osg-banner-logo {
      width: 80px; } }
  .osg-banner-logo--svg {
    margin: 0 0 5px 0; }
  .osg-banner-logo--name {
    color: #6D6E71;
    display: none;
    font-size: 0.5rem;
    font-weight: 600;
    line-height: 1.25;
    margin: 0;
    padding: 0; }
    @media (min-width: 1280px) {
      .osg-banner-logo--name {
        display: block; } }
    @media (min-width: 1440px) {
      .osg-banner-logo--name {
        margin-left: 5px; } }
    @media (min-width: 1920px) {
      .osg-banner-logo--name {
        font-size: .75rem; } }

.osg-menu {
  -ms-flex-preferred-size: 9%;
      flex-basis: 9%;
  margin: 0;
  overflow: hidden; }
  .osg-menu:hover {
    cursor: pointer; }
    .osg-menu:hover .osg-menu--bar:nth-child(3) {
      width: 100%; }
    .osg-menu:hover .osg-menu--bar:nth-child(4) {
      width: 100%; }
  @media (min-width: 768px) {
    .osg-menu {
      -ms-flex-preferred-size: 5%;
          flex-basis: 5%; } }
  @media (min-width: 1024px) {
    .osg-menu {
      -ms-flex-preferred-size: 4%;
          flex-basis: 4%;
      height: 65px;
      max-width: 45px;
      text-align: center;
      letter-spacing: 2px; } }
  .osg-menu--label {
    color: #fff;
    display: none; }
    @media (min-width: 1440px) {
      .osg-menu--label {
        display: inline-block;
        font-size: 10px; } }
  .osg-menu--bar {
    background: #00aeef;
    height: 2px;
    margin: 7px 0;
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
    width: 100%; }
    .osg-menu--bar:nth-child(3) {
      width: 80%; }
    .osg-menu--bar:nth-child(4) {
      width: 60%; }
    @media (min-width: 1920px) {
      .osg-menu--bar {
        height: 3px;
        margin: 10px 0; } }

.osg-main {
  background: #fff;
  position: relative; }
  @media (min-width: 1280px) {
    .osg-main {
      position: absolute;
      top: 100vh; } }

.osg-services {
  padding: 20px; }
  @media (min-width: 1024px) {
    .osg-services {
      padding: 50px; } }
  @media (min-width: 1280px) {
    .osg-services {
      padding: 50px; } }
  @media (min-width: 1920px) {
    .osg-services {
      padding: 50px; } }
  .osg-services--heading {
    color: #222;
    font-size: 2.4rem;
    margin: 0;
    text-align: center;
    padding: 0; }
    @media (min-width: 768px) {
      .osg-services--heading {
        font-size: 3rem; } }
    @media (min-width: 1024px) {
      .osg-services--heading {
        font-size: 4rem;
        text-align: left; } }
    @media (min-width: 1440px) {
      .osg-services--heading {
        font-size: 5.5rem;
        text-align: left; } }
    @media (min-width: 1920px) {
      .osg-services--heading {
        font-size: 7rem;
        text-align: left; } }
    .osg-services--heading.works {
      color: #fff; }
  .osg-services--subheading {
    color: #6D6E71;
    font-size: .80rem;
    line-height: 1.5;
    margin: 5px 0 0 0;
    text-align: center;
    padding: 0 10px; }
    .osg-services--subheading_social {
      text-decoration: none;
      height: 50px;
      width: 50px; }
      .osg-services--subheading_social-img {
        height: 35px;
        width: 35px; }
    @media (min-width: 768px) {
      .osg-services--subheading {
        font-size: 1rem; } }
    @media (min-width: 1024px) {
      .osg-services--subheading {
        padding: 0;
        text-align: left; } }
    @media (min-width: 1440px) {
      .osg-services--subheading {
        font-size: 1.25rem;
        margin: 0px 0 0 15px;
        text-align: left;
        width: 35%; } }
    @media (min-width: 2560px) {
      .osg-services--subheading {
        font-size: 1rem;
        margin: 0 0 0 15px;
        text-align: left; } }
  .osg-services--panels {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 10px 0; }
    @media (min-width: 768px) {
      .osg-services--panels {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly; } }
    @media (min-width: 1280px) {
      .osg-services--panels {
        margin: 60px 0 50px 0; } }
    @media (min-width: 2560px) {
      .osg-services--panels {
        margin: 60px 0 50px 0; } }
  .osg-services--panel {
    border: thin solid rgba(174, 174, 174, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin: 25px auto 0;
    opacity: 0;
    text-align: center;
    -webkit-transition: all 1s ease-out;
    transition: all 1s ease-out;
    width: 100%; }
    .osg-services--panel.fade-in {
      opacity: 1; }
    @media (min-width: 768px) {
      .osg-services--panel {
        width: 48%; } }
    @media (min-width: 1024px) {
      .osg-services--panel {
        padding: 50px 20px;
        width: 23%; } }
    @media (min-width: 1440px) {
      .osg-services--panel {
        margin: 0px auto 25px;
        padding: 40px 10px; } }
    @media (min-width: 1920px) {
      .osg-services--panel {
        margin: 90px auto;
        padding: 80px 80px;
        width: 24%; } }
    .osg-services--panel_icon {
      background: url(../images/osg-icon-services--web.png) top center no-repeat;
      background-size: contain;
      height: 150px;
      margin: -45px auto 0;
      width: 150px; }
      @media (min-width: 1024px) {
        .osg-services--panel_icon {
          margin: -85px auto 50px;
          width: 250px; } }
    .osg-services--panel_heading {
      font-size: 1.5rem;
      font-weight: 400;
      margin: 0 0 10px 0;
      padding: 0; }
      @media (min-width: 1024px) {
        .osg-services--panel_heading {
          font-size: 1.25rem; } }
      @media (min-width: 1440px) {
        .osg-services--panel_heading {
          font-size: 1.5rem; } }
      @media (min-width: 1920px) {
        .osg-services--panel_heading {
          font-size: 1.75rem; } }
    .osg-services--panel_description {
      color: #6D6E71;
      font-size: .80rem;
      line-height: 1.5;
      margin: 0 0 20px 0;
      padding: 0; }
      @media (min-width: 1024px) {
        .osg-services--panel_description {
          font-size: .75rem;
          line-height: 1.5; } }
      @media (min-width: 1440px) {
        .osg-services--panel_description {
          font-size: .85rem;
          line-height: 1.5; } }
      @media (min-width: 1920px) {
        .osg-services--panel_description {
          font-size: .95rem;
          line-height: 1.5;
          margin-bottom: 50px; } }
    .osg-services--panel_link {
      color: #222;
      font-size: 0.5rem;
      font-weight: 600;
      height: 100px;
      margin: -35px auto 0;
      text-decoration: none;
      width: 100px; }
      .osg-services--panel_link:hover {
        color: #222; }
      @media (min-width: 1920px) {
        .osg-services--panel_link {
          font-size: .75rem; } }

.osg-pricing {
  background: #f7f7f7;
  padding: 20px; }
  @media (min-width: 1024px) {
    .osg-pricing {
      padding: 50px; } }
  @media (min-width: 1280px) {
    .osg-pricing {
      padding: 50px; } }
  @media (min-width: 1920px) {
    .osg-pricing {
      padding: 50px; } }
  .osg-pricing--heading {
    color: #222;
    font-size: 2.4rem;
    margin: 10px 0 0 0;
    text-align: center;
    padding: 0; }
    @media (min-width: 768px) {
      .osg-pricing--heading {
        font-size: 3rem; } }
    @media (min-width: 1024px) {
      .osg-pricing--heading {
        font-size: 4rem;
        text-align: left; } }
    @media (min-width: 1440px) {
      .osg-pricing--heading {
        font-size: 5.5rem;
        text-align: left; } }
    @media (min-width: 1920px) {
      .osg-pricing--heading {
        font-size: 7rem;
        text-align: left; } }
    .osg-pricing--heading.works {
      color: #fff; }
  .osg-pricing--subheading {
    color: #6D6E71;
    font-size: .65rem;
    margin: 5px 0 0 0;
    text-align: center;
    padding: 0; }
    @media (min-width: 768px) {
      .osg-pricing--subheading {
        font-size: 1rem; } }
    @media (min-width: 1024px) {
      .osg-pricing--subheading {
        text-align: left; } }
    @media (min-width: 1440px) {
      .osg-pricing--subheading {
        font-size: 1.25rem;
        margin: 0px 0 0 15px;
        text-align: left; } }
    @media (min-width: 2560px) {
      .osg-pricing--subheading {
        font-size: 1.25rem;
        margin: 0 0 0 15px;
        text-align: left; } }
  .osg-pricing--panels {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 25px 0; }
    @media (min-width: 768px) {
      .osg-pricing--panels {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly; } }
    @media (min-width: 1280px) {
      .osg-pricing--panels {
        margin: 60px 0 50px 0; } }
    @media (min-width: 2560px) {
      .osg-pricing--panels {
        margin: 60px 0 50px 0; } }
  .osg-pricing--panel {
    border: thin solid rgba(174, 174, 174, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin: 25px auto 0;
    opacity: 0;
    text-align: center;
    -webkit-transition: all 1s ease-out;
    transition: all 1s ease-out;
    width: 100%; }
    .osg-pricing--panel.fade-in {
      opacity: 1; }
    @media (min-width: 768px) {
      .osg-pricing--panel {
        width: 48%; } }
    @media (min-width: 1024px) {
      .osg-pricing--panel {
        padding: 50px 20px;
        width: 31%; } }
    @media (min-width: 1440px) {
      .osg-pricing--panel {
        margin: 0px auto 25px;
        padding: 40px 10px; } }
    @media (min-width: 1920px) {
      .osg-pricing--panel {
        margin: 90px auto;
        padding: 80px 80px;
        width: 24%; } }
    .osg-pricing--panel_icon {
      background: url(../images/osg-icon-services--web.png) top center no-repeat;
      background-size: contain;
      height: 150px;
      margin: -45px auto 0;
      width: 150px; }
      @media (min-width: 1024px) {
        .osg-pricing--panel_icon {
          margin: -85px auto 50px;
          width: 250px; } }
    .osg-pricing--panel_heading {
      font-size: 1.5rem;
      font-weight: 400;
      margin: 0;
      padding: 0; }
      @media (min-width: 1024px) {
        .osg-pricing--panel_heading {
          font-size: 1.25rem; } }
      @media (min-width: 1920px) {
        .osg-pricing--panel_heading {
          font-size: 1.55rem; } }
    .osg-pricing--panel_subheading {
      font-size: 2.4rem;
      font-weight: 600;
      margin: 0;
      padding: 15px 0; }
      @media (min-width: 1024px) {
        .osg-pricing--panel_subheading {
          font-size: 3.5rem; } }
      @media (min-width: 1920px) {
        .osg-pricing--panel_subheading {
          font-size: 3.75rem; } }
    .osg-pricing--panel_description {
      color: #6D6E71;
      font-size: 0.89rem;
      line-height: 1.5;
      margin: 0 0 20px 0;
      padding: 0; }
      @media (min-width: 1024px) {
        .osg-pricing--panel_description {
          font-size: .75rem;
          line-height: 1.5; } }
      @media (min-width: 1440px) {
        .osg-pricing--panel_description {
          font-size: 1rem;
          line-height: 1.5;
          padding: 0 25px; } }
      @media (min-width: 1920px) {
        .osg-pricing--panel_description {
          font-size: .95rem;
          line-height: 1.5;
          margin-bottom: 50px; } }
    .osg-pricing--panel_link {
      color: #222;
      font-size: 0.5rem;
      font-weight: 600;
      height: 100px;
      margin: -35px auto 0;
      text-decoration: none;
      width: 100px; }
      .osg-pricing--panel_link:hover {
        color: #222; }
      @media (min-width: 1920px) {
        .osg-pricing--panel_link {
          font-size: .75rem; } }

.osg-works {
  background: #080a09 url(../images/osg-works-background.jpg) top center no-repeat;
  padding: 25px 15px;
  position: relative; }
  @media (min-width: 1440px) {
    .osg-works {
      padding: 50px; } }
  .osg-works--pills {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: relative; }
    @media (min-width: 768px) {
      .osg-works--pills {
        margin: 20px auto 10px;
        width: 50%; } }
    @media (min-width: 1440px) {
      .osg-works--pills {
        margin: 20px auto 10px;
        width: 30%; } }
    @media (min-width: 1920px) {
      .osg-works--pills {
        margin: 120px auto 10px;
        width: 25%; } }
  .osg-works--gallery {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative; }
    @media (min-width: 768px) {
      .osg-works--gallery {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly; } }
    @media (min-width: 1920px) {
      .osg-works--gallery {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly; } }
    .osg-works--gallery_item {
      height: 250px;
      margin: 10px 0;
      opacity: 0;
      position: relative;
      -webkit-transition: all 1s ease-out;
      transition: all 1s ease-out;
      width: 100%; }
      .osg-works--gallery_item.fade-in {
        opacity: 1; }
      .osg-works--gallery_item-overlay {
        background: rgba(34, 34, 34, 0.9);
        color: #fff;
        height: 100%;
        opacity: 0;
        position: absolute;
        text-align: center;
        -webkit-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;
        width: 100%;
        z-index: 10; }
        .osg-works--gallery_item-overlay:hover {
          cursor: pointer;
          opacity: 1; }
          .osg-works--gallery_item-overlay:hover .osg-works--gallery_item-overlay_text {
            top: 50%;
            opacity: 1; }
        .osg-works--gallery_item-overlay_text {
          left: 50%;
          opacity: 0;
          padding: 50px 120px;
          position: absolute;
          top: 60%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          -webkit-transition: all .25s ease-in-out;
          transition: all .25s ease-in-out;
          width: 100%; }
      .osg-works--gallery_item-name {
        color: #00aeef;
        font-size: 28px;
        font-weight: 600;
        margin: 0;
        padding: 0; }
      .osg-works--gallery_item-desc {
        color: #aeaeae;
        font-size: 12px;
        line-height: 1.5;
        margin: 0;
        padding: 10px 0; }
      .osg-works--gallery_item-view {
        color: #fff;
        display: block;
        font-size: 9px;
        font-weight: 600;
        margin: 15px 0 0 0;
        padding: 10px 0;
        text-decoration: none; }
      @media (min-width: 768px) {
        .osg-works--gallery_item {
          width: 49%; } }
      @media (min-width: 1280px) {
        .osg-works--gallery_item {
          height: 450px;
          margin: 5px 0; } }
      @media (min-width: 1920px) {
        .osg-works--gallery_item {
          height: 300px;
          margin: 9px 0;
          width: 24%; } }
      @media (min-width: 2560px) {
        .osg-works--gallery_item {
          height: 400px;
          margin: 10px 0;
          width: 24%; } }
      .osg-works--gallery_item.item1 {
        background: url(../images/osg-gallery_item-1.jpg) top center no-repeat;
        background-size: cover; }
      .osg-works--gallery_item.item2 {
        background: url(../images/osg-gallery_item-1.jpg) top center no-repeat;
        background-size: cover; }
      .osg-works--gallery_item.item3 {
        background: url(../images/osg-gallery_item-1.jpg) top center no-repeat;
        background-size: cover; }
      .osg-works--gallery_item.item4 {
        background: url(../images/osg-gallery_item-1.jpg) top center no-repeat;
        background-size: cover; }
  .osg-works--clients {
    background: #222;
    border: thin solid #222;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: -5px 0 0 0;
    padding: 30px 0;
    width: 100%; }
    @media (min-width: 1024px) {
      .osg-works--clients {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; } }
    .osg-works--clients_logo {
      background: #3a3a3a;
      margin: 0 10px;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      margin: 10px auto;
      padding: 50px 50px;
      width: 80%; }
      @media (min-width: 1024px) {
        .osg-works--clients_logo {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          margin: 10px 5px; } }

.osg-contact {
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%; }
  @media (min-width: 1024px) {
    .osg-contact {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      padding: 50px; } }
  .osg-contact--wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
    width: 100%; }
    @media (min-width: 1024px) {
      .osg-contact--wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin: 100px auto;
        width: 90%; } }
    @media (min-width: 1440px) {
      .osg-contact--wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin: 180px auto;
        width: 50%; } }
    @media (min-width: 1920px) {
      .osg-contact--wrapper {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin: 180px auto;
        width: 40%; } }
  .osg-contact--info {
    -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    padding: 0;
    position: relative; }
    @media (min-width: 768px) {
      .osg-contact--info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; } }
    @media (min-width: 1024px) {
      .osg-contact--info {
        border-right: thin solid rgba(174, 174, 174, 0.5);
        display: block;
        padding: 50px; } }
    .osg-contact--info_details {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 20px 0; }
      .osg-contact--info_details-icon {
        background: #222;
        height: 40px;
        margin-right: 20px;
        width: 40px; }
      .osg-contact--info_details-text {
        border-left: thin solid #aeaeae;
        padding: 0 15px;
        position: relative;
        text-align: left; }
      .osg-contact--info_details-type {
        margin: 0;
        padding: 0; }
      .osg-contact--info_details-contact {
        margin: 0;
        padding: 0; }
  .osg-contact--form {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    position: relative; }
    .osg-contact--form_thankyou {
      background: rgba(34, 34, 34, 0.95);
      left: 50%;
      padding: 240px 0;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 100%; }
      .osg-contact--form_thankyou-confirm, .osg-contact--form_thankyou-message {
        color: #fff;
        margin: 0;
        padding: 0; }
    .osg-contact--form_group {
      border: none; }
      .osg-contact--form_group-label {
        display: none; }
      .osg-contact--form_group-input {
        border: thin solid #aeaeae;
        padding: 20px;
        width: 100%; }
        .osg-contact--form_group-input.msg {
          height: 200px; }
  .osg-contact .osg-services--subheading {
    margin-bottom: 25px; }
  .osg-contact--input {
    border: transparent;
    border-bottom: thin solid rgba(34, 34, 34, 0.25);
    padding: 20px 10px;
    width: 100%; }
    @media (min-width: 768px) {
      .osg-contact--input {
        margin: 10px 0;
        width: 75%; } }
    @media (min-width: 1440px) {
      .osg-contact--input {
        margin: 20px 0;
        width: 75%; } }

.osg-footer {
  background: #222;
  padding: 15px 0 30px 0;
  text-align: center; }
  .osg-footer .osg-logo--svg {
    margin: 0;
    padding: 5px 0;
    width: 15%; }
    @media (min-width: 768px) {
      .osg-footer .osg-logo--svg {
        margin: 10px 0;
        min-width: 40px; } }
  .osg-footer--privacy {
    padding: 25px 0 0 0;
    position: relative; }
    .osg-footer--privacy_link {
      font-size: 8px;
      font-weight: 600;
      color: #6D6E71;
      text-decoration: none; }

